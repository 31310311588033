@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:800,500,400,700|Inter:400,700");
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
html,
body {
  margin: 0px;
  height: 100%;
}
/* a blue color as a generic focus style */
button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}
a {
  text-decoration: none;
}

.flight-hacks-wine {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.flight-hacks-wine .div {
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.flight-hacks-wine .overlap {
  position: absolute;
  width: 100%;
  height: 1129px;
  top: 2296px;
  left: -198px;
}

.flight-hacks-wine .group {
  position: absolute;
   width: 100%;
  height: 932px;
  top: 198px;
  left: 0;
}

.flight-hacks-wine .overlap-group {
  position: relative;

}

.flight-hacks-wine .ellipse {
  position: absolute;
  width: 100%;
  height: 441px;
  top: 90px;
  left: 0;
  background-color: #615be3;
  border-radius: 912.42px/320.71px;
  filter: blur(115.9px);
}

.flight-hacks-wine .ellipse-2 {
  position: absolute;
  width: 100%;
  height:600px;
  top: 128px;
  left: 0;
  background-color: #ffffff;
  border-radius: 917.82px/451.78px;
  filter: blur(130px);
}

.flight-hacks-wine .group-2 {
	margin-top:20px;
  position: relative;
  width: 100%;
  height: 120px;
}

.flight-hacks-wine .frame {
  display: inline-flex;
  align-items: flex-start;
  gap: 16px;
  position: absolute;
  top: 52px;
  left: 0;
}

.flight-hacks-wine .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  margin-left:20px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #1e2128;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: underline;
  white-space: nowrap;
}

.flight-hacks-wine .group-3 {
  position: absolute;
  width: 201px;
  height: 22px;
  top: 54px;
  left: 416px;
}

.flight-hacks-wine .overlap-group-2 {
  position: relative;
  height: 22px;
}
.flight-hacks-wine .overlap-group-22 {
  position: relative;
  width: 113px;
  height: 22px;
  top: 0;
  left:89px;
  z-index:1;
}

.flight-hacks-wine .union {
  position: relative;
  width: 250px;
  height: 39px;
}

.flight-hacks-wine .text-wrapper-2 {
  position: absolute;
  width: 92px;
  height: 22px;
  top: 0;
  left: 21px;
  font-family: "Hando Trial-Black", Helvetica;
  font-weight: 900;
  color: #1e2128;
  font-size: 31.8px;
  text-align: center;
  letter-spacing: 0;
  line-height: 31.8px;
}

.flight-hacks-wine .text-wrapper-3 {
  position: relative;
  width: 84px;
  height: 22px;
  top: 0;
  left: 0;
  font-family: "Hando Trial-Black", Helvetica;
  font-weight: 900;
  color: #1e2128;
  font-size: 31.8px;
  text-align: center;
  letter-spacing: 0;
  line-height: 31.8px;
  z-index:2;
}

.flight-hacks-wine .frame-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 52px;
  left: 755px;
}

.flight-hacks-wine .ic-baseline {
	margin-right:10px;
  position: relative;
  width: 24px;
  height: 24px;
}

.flight-hacks-wine .p {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #1e2128;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.flight-hacks-wine .line {
  position: relative;
  width: 100%;
  height:2px;
  top: -1px;
  left: 0;
  object-fit: cover;
}

.flight-hacks-wine .frame-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  position: absolute;
  width:100%;
 
}

.flight-hacks-wine .frame-4 {
  text-align:center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.flight-hacks-wine .frame-44 {
	 display: inline-flex;
  text-align:center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.flight-hacks-wine .text-wrapper-4 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  color: #615be3;
  font-size: 32px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
  white-space: nowrap;
}

.flight-hacks-wine .enjoy-our-free {
  position: relative;
  width: 459.53px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #1e2128;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.4px;
}

.flight-hacks-wine .frame-5 {
  display: inline-flex;
  align-items: flex-start;
  gap: 40px;
  position: relative;
  flex: 0 0 auto;
  width: 1000px;
}

.flight-hacks-wine .image {
  position: relative;
  width: 275px;
  height: 174.17px;
  object-fit: cover;
}

.flight-hacks-wine .american-express {
  position: relative;
  width: fit-content;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: #1e2128;
  font-size: 12px;
  text-align: center;
  line-height: normal;
}

.flight-hacks-wine .span {
  letter-spacing: 0.12px;
}

.flight-hacks-wine .text-wrapper-5 {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
}

.flight-hacks-wine .component {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 42px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid;
  border-color: #615be3;
  color: #615be3;
}

.btn-outline-primary:hover {
	color:white;
}

.flight-hacks-wine .text-wrapper-6 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Inter", Helvetica;
  font-weight: 700;
  
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.flight-hacks-wine .img {
  position: relative;
  width: 275px;
  height: 173.59px;
  object-fit: cover;
}

.flight-hacks-wine .overlap-2 {
  position: absolute;
  width: 1440px;
  height: 1191px;
  top: 0;
  left: 0;
}

.flight-hacks-wine .radar {
  position: absolute;
  width: 1221px;
  height: 1160px;
  top: 0;
  left: 110px;
}

.flight-hacks-wine .union-2 {
  position: absolute;
  width: 544px;
  height: 340px;
  top: -5px;
  left: 55px;
}

.flight-hacks-wine .union-3 {
  position: absolute;
  max-width: 513px;
  max-height: 604px;
  top: 100px;
  right: 0;
  padding:0;
}

.flight-hacks-wine .nav-bar {
  position: absolute;
  width: 1440px;
  height: 61px;
  top: 0;
  left: 0;
  background-color: #1e2128;
}

.flight-hacks-wine .frame-6 {
  display: flex;
  width: 1056px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 19px;
  left: 192px;
}

.flight-hacks-wine .group-4 {
  position: relative;
  width: 201.25px;
  height: 35px;
}

.flight-hacks-wine .text-wrapper-7 {
  position: absolute;
  width: 92px;
  height: 22px;
  top: 0;
  left: 21px;
  font-family: "Hando Trial-Black", Helvetica;
  font-weight: 900;
  color: #f8f8f8;
  font-size: 31.8px;
  text-align: center;
  letter-spacing: 0;
  line-height: 31.8px;
}

.flight-hacks-wine .text-wrapper-8 {
  position: absolute;
  width: 84px;
  height: 22px;
  top: 0;
  left: 0;
  font-family: "Hando Trial-Black", Helvetica;
  font-weight: 900;
  color: #f8f8f8;
  font-size: 31.8px;
  text-align: center;
  letter-spacing: 0;
  line-height: 31.8px;
  z-index:2;
}

.flight-hacks-wine .frame-7 {
  display: inline-flex;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  flex: 0 0 auto;
}

.flight-hacks-wine .text {
  position: relative;
  width: 44.65px;
  height: 17px;
}

.flight-hacks-wine .link-home {
  position: absolute;
  width: 45px;
  height: 17px;
  top: -1px;
  left: 0;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.flight-hacks-wine .link-home-wrapper {
  position: relative;
  width: 59px;
  height: 17px;
}

.flight-hacks-wine .link-home-2 {
  position: absolute;
  width: 59px;
  height: 17px;
  top: -1px;
  left: 0;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.flight-hacks-wine .frame-8 {
  position: relative;
  flex: 0 0 auto;
}

.flight-hacks-wine .overlap-wrapper {
  max-width:1270px;
  margin-top:70px;
  height: 895px;
  background-color: #1e2128;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.flight-hacks-wine .overlap-3 {
  position: relative;
  height: 895px;
}

.flight-hacks-wine .ellipse-3 {
  position: absolute;
  width: 529px;
  height: 895px;
  top: 0;
  left: 0;
}

.flight-hacks-wine .ellipse-4 {
  position: absolute;
  width: 676px;
  height: 895px;
  top: 131px;
  right: 0;
}

.flight-hacks-wine .rectangle {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  mix-blend-mode: overlay;
}

.flight-hacks-wine .frame-9 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  left: 0;
  padding:90px;
}

.flight-hacks-wine .frame-10 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
  margin-top:10px;
}

.flight-hacks-wine .img-2 {
  position: relative;
  width: 32px;
  height: 32px;
}

.flight-hacks-wine .text-wrapper-9 {
  position: relative;
  width: 228px;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  color: #f8f8f8;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
}

.flight-hacks-wine .frame-11 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 9.23px;
  position: relative;
  flex: 0 0 auto;
  margin-top:10px;
}

.flight-hacks-wine .text-wrapper-10 {
  position: relative;
  width: fit-content;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #f8f8f8;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}

.flight-hacks-wine .frame-12 {
  display: inline-flex;
  align-items: flex-start;
  gap: 18.46px;
  position: relative;
  flex: 0 0 auto;
}

.flight-hacks-wine .frame-13 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4.61px;
  position: relative;
  flex: 0 0 auto;
}

.flight-hacks-wine .img-3 {
  position: relative;
  width: 20px;
  height: 20px;
}

.flight-hacks-wine .text-wrapper-11 {
  position: relative;
  width: fit-content;
  margin-top: -1.15px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #f8f8f8;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20.8px;
  white-space: nowrap;
}

.flight-hacks-wine .frame-14 {
  display: inline-flex;
  align-items: flex-start;
  gap: 4.61px;
  position: relative;
  flex: 0 0 auto;
}

.flight-hacks-wine .frame-15 {
  display: flex;
  width: 176px;
  align-items: center;
  gap: 24px;
  padding: 8px 27px 8px 8px;
  position: relative;
  background-color: #f8f8f8;
  border-radius: 4px;
  border: 1px solid;
  border-color: #c4c0e1;
  font-family: "Roboto", Helvetica;
  font-size:14px;
  margin-top:10px;
}

.flight-hacks-wine .text-wrapper-12 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #5d5a87;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.flight-hacks-wine .caret-down {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: -16px;
}

.flight-hacks-wine .frame-16 {
  
  flex-direction: column;
  align-items: center;
  gap: 39px;
  position: relative;
}

.flight-hacks-wine .frame-17 {
  display: inline-flex;
  align-items: flex-end;
  gap: 13.84px;
  position: relative;
  flex: 0 0 auto;
  max-width:992px;
}

.flight-hacks-wine .text-wrapper-13 {
  position: relative;
  font-family: "Roboto", Helvetica;
  font-weight: 800;
  color: #1e2128;
  font-size: 48px;
  letter-spacing: 0;
  line-height: 55.4px;
  white-space: nowrap;
}

.flight-hacks-wine .overlap-group-wrapper {
  position: relative;
  width: 49.86px;
  height: 64.67px;
}

.flight-hacks-wine .overlap-4 {
  position: relative;
  width: 50px;
  height: 65px;
}

.flight-hacks-wine .overlap-5 {
  position: absolute;
  width: 50px;
  height: 63px;
  top: 2px;
  left: 0;
}

.flight-hacks-wine .overlap-group-3 {
  position: absolute;
  width: 50px;
  height: 58px;
  top: 5px;
  left: 0;
}

.flight-hacks-wine .group-5 {
  position: absolute;
  width: 27px;
  height: 53px;
  top: 0;
  left: 0;
}

.flight-hacks-wine .group-6 {
  position: absolute;
  width: 27px;
  height: 53px;
  top: 5px;
  left: 23px;
}

.flight-hacks-wine .rectangle-2 {
  position: absolute;
  width: 1px;
  height: 6px;
  top: 0;
  left: 30px;
  background-color: #615be3;
  transform: rotate(-150.54deg);
}

.flight-hacks-wine .rectangle-3 {
  position: absolute;
  width: 1px;
  height: 6px;
  top: 0;
  left: 27px;
  background-color: #615be3;
  transform: rotate(5.92deg);
}

.flight-hacks-wine .rectangle-4 {
  position: absolute;
  width: 1px;
  height: 6px;
  top: 1px;
  left: 23px;
  background-color: #615be3;
  transform: rotate(-17.62deg);
}

.flight-hacks-wine .uncork-the-best {
  position: relative;
  width: 100%;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #615be3;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  margin-top:15px;
  
}

.flight-hacks-wine .text-wrapper-14 {
  font-weight: 700;
}

.flight-hacks-wine .text-wrapper-15 {
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #615be3;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
}

.flight-hacks-wine .text-wrapper-16 {
  position: relative;
  max-width: 472.85px;
  padding-top: 40px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #1e2128;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 21px;
  margin:auto;
}

.flight-hacks-wine .frame-18 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  position: relative;
  flex: 0 0 auto;
  margin-top:15px;
}

.flight-hacks-wine .attachment {
  position: relative;
  width: 40px;
  height: 40.03px;
  object-fit: cover;
}

.flight-hacks-wine .text-wrapper-17 {
  position: relative;
  width: fit-content;
  font-family: "Roboto", Helvetica;
  font-weight: 500;
  color: #615be3;
  font-size: 15.5px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.3px;
  white-space: nowrap;
}

.frame-19::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.frame-19::-webkit-scrollbar
{
	height:10px;
	width: 3px;
	background-color: transparent;
}

.frame-19::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.frame-24::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.frame-24::-webkit-scrollbar
{
	height:10px;
	width: 3px;
	background-color: transparent;
}

.frame-24::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.flight-hacks-wine .frame-19 {
  width:100%;
  text-align:center;
  position: absolute;
  top:250px;
  border-radius: 0px 0px 0px 0px;
  
  overflow-x:auto;
  padding-top:0px;
  padding-bottom:0px;
  
  max-width:1077px;
}

.flight-hacks-wine .frame-20 {
  display: inline-flex;
  margin:auto;
  justify-content: center;
    align-items: center;
  text-align:center;
  position: relative;
  width:100%;
}

.flight-hacks-wine .table-header-row {
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  background-color: #2c303b;
  border-radius: 16px 0px 0px 0px;
  height:71px;
}

.flight-hacks-wine .text-wrapper-18 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  color: #f8f8f8;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
}

.flight-hacks-wine .table-header-row-2 {
  width: 14%;
  background-color: #2c303b;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .fa-solid-sort {
  position: relative;
  width: 8.87px;
  height: 14.19px;
}

.flight-hacks-wine .table-header-row-3 {
  width: 14%;
  background-color: #2c303b;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .text-wrapper-19 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  margin-left: -1.43px;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  color: #f8f8f8;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
}

.flight-hacks-wine .fa-solid-sort-2 {
  margin-right: -1.43px;
  position: relative;
  width: 8.87px;
  height: 14.19px;
}

.flight-hacks-wine .table-header-row-4 {
  width: 14%;
  background-color: #2c303b;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .text-wrapper-20 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  margin-left: -3.43px;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  color: #f8f8f8;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
}

.flight-hacks-wine .fa-solid-sort-3 {
  margin-right: -3.43px;
  position: relative;
  width: 8.87px;
  height: 14.19px;
}

.flight-hacks-wine .table-header-row-5 {
  width: 14%;
  background-color: #2c303b;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .table-header-row-6 {
  width: 14%;
  background-color: #2c303b;
  border-radius: 0px 16px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .div-2 {
  display: inline-flex;
  height: 64px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -1px;
  background-color: #f8f8f8;
  width:100%;
}

.flight-hacks-wine .table-body-row-data {
  display: flex;
  width: 30%;
  align-items: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
  border: 1px solid;
  border-color: #d8d7ec;
}

.flight-hacks-wine .text-wrapper-21 {
  color: #1e2128;
  position: relative;
  width: 100%;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align:center;
}

.flight-hacks-wine .div-wrapper {
  width: 14%;
  justify-content: center;
  margin-left: -1px;
  border: 1px solid;
  border-color: #d8d7ec;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-2 {
  width: 14%;
  justify-content: center;
  gap: 8px;
  margin-left: -1px;
  border: 1px solid;
  border-color: #d8d7ec;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-3 {
  width: 14%;
  justify-content: center;
  gap: 8px;
  margin-left: -1px;
  border: 1px solid;
  border-color: #d8d7ec;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-4 {
  width: 14%;
  justify-content: center;
  gap: 8px;
  margin-left: -1px;
  border: 1px solid;
  border-color: #d8d7ec;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .text-wrapper-22 {
  position: relative;
  width: 100%;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #1e2128;
  font-size: 15.9px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.flight-hacks-wine .text-wrapper-23 {
  color: #000000;
  position: relative;
  width: fit-content;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align:left;
}

.flight-hacks-wine .heirloom-vineyards {
  position: relative;
  width: fit-content;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 15.9px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.flight-hacks-wine .frame-21 {
  display: inline-flex;
  height: 64px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -1px;
  background-color: #f8f8f8;
  border-radius: 0px 0px 16px 16px;
  width:100%;
}

.flight-hacks-wine .heirloom-vineyards-wrapper {
  width: 30%;
  gap: 8px;
  border-radius: 0px 0px 0px 16px;
  border: 1px solid;
  border-color: #d8d7ec;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .element-wrapper {
  width: 14%;
  justify-content: center;
  gap: 8px;
  margin-left: -1px;
  border-radius: 0px 0px 16px 0px;
  border: 1px solid;
  border-color: #d8d7ec;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .overlap-6 {
  position:relative;
  width: 100%;
  height: 75px;
}

.flight-hacks-wine .frame-22 {
  display: flex;
  width: 1047px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
}

.flight-hacks-wine .frame-23 {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.flight-hacks-wine .text-wrapper-24 {
  position: relative;
  width: fit-content;
  font-family: "Roboto", Helvetica;
  font-weight: 500;
  color: #1e2128;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.flight-hacks-wine .here-s-where-we {
  position: absolute;
  width: 355px;
  height: 36px;
  top: 39px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #615be3;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16.8px;
}

.flight-hacks-wine .frame-24 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  overflow-x:auto;
  background-color: #ffffff;
  max-width: 1056px
}

.flight-hacks-wine .frame-25 {
  display: flex;
  height: 71px;
  align-items: flex-start;
  position: relative;
  width:100%;
}

.flight-hacks-wine .table-header-row-7 {
  width: 30%;
  background-color: #1e2128;
  border-radius: 16px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .table-header-row-8 {
  width: 14%;
  background-color: #1e2128;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .table-header-row-9 {
  width: 14%;
  background-color: #1e2128;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .text-wrapper-25 {
  position: relative;
  width: 100%;
  margin-top: -1px;
  margin-left: -0.93px;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  color: #f8f8f8;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.flight-hacks-wine .fa-solid-sort-4 {
  margin-right: -0.93px;
  position: relative;
  width: 8.87px;
  height: 14.19px;
}

.flight-hacks-wine .table-header-row-10 {
  width: 14%;
  background-color: #1e2128;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .text-wrapper-26 {
  position: relative;
  width: 100%;
  margin-top: -1px;
  margin-left: -4.93px;
  font-family: "Roboto", Helvetica;
  font-weight: 700;
  color: #f8f8f8;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.flight-hacks-wine .fa-solid-sort-5 {
  margin-right: -4.93px;
  position: relative;
  width: 8.87px;
  height: 14.19px;
}

.flight-hacks-wine .table-header-row-11 {
  width: 14%;
  background-color: #1e2128;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .table-header-row-12 {
  width: 14%;
  background-color: #1e2128;
  border-radius: 0px 16px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 17px;
  position: relative;
  height:71px;
}

.flight-hacks-wine .div-3 {
  display: flex;
  width: 100%;
  height: 64px;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #615be3;
}

.flight-hacks-wine .table-body-row-data-5 {
  width: 30%;
  gap: 8px;
  margin-left: -1.5px;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-6 {
  width: 14%;
  justify-content: center;
  margin-left: -1px;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-7 {
  width: 14%;
  justify-content: center;
  gap: 8px;
  margin-left: -1px;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-8 {
  width: 14%;
  justify-content: center;
  gap: 8px;
  margin-left: -1px;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-9 {
  width: 14%;
  justify-content: center;
  gap: 8px;
  margin-right: -1.5px;
  margin-left: -1px;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-10 {
  width: 14%;
  gap: 8px;
  margin-left: -4px;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-11 {
  width: 14%;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-12 {
  width: 14%;
  justify-content: center;
  gap: 8px;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-13 {
  width: 14%;
  justify-content: center;
  gap: 8px;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}

.flight-hacks-wine .table-body-row-data-14 {
  width: 14%;
  justify-content: center;
  gap: 8px;
  margin-right: -4px;
  display: flex;
  align-items: center;
  padding: 12px 17px;
  position: relative;
  align-self: stretch;
}


/* ------------------------------------------
			  RESPONSIVE NAV STYLES
--------------------------------------------- */



@media screen and (max-width: 991px) {
  .head1, .head2 {
	text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
	padding:0;
	margin: auto;
  }
  .union-2{
	height: 240px !important;
	left:-40px !important;
  }
  .union-3{
	max-height:372px !important;
	top: 370px !important;
	right:-40px !important;
  }
  .container-fluid{
	margin-right:0px !important;
  }
  
  .flight-hacks-wine .frame-9 {
	
	  padding-left:0px;
	  padding-right:0px;
	}
	.text-wrapper-18, .text-wrapper-19, .text-wrapper-20, .text-wrapper-21, .text-wrapper-22, .text-wrapper-23, .text-wrapper-25, .text-wrapper-26 {
		font-size:13px !important;
		line-height: 19px !important;
	}
	
	.table-body-row-data, .table-body-row-data-4, .div-wrapper, .table-body-row-data-2, .table-body-row-data-3, .table-body-row-data-5, .table-body-row-data-6, .table-body-row-data-7, .table-body-row-data-8, .table-body-row-data-9  {
		padding:5px !important;
	}
	
	.frame-19{
		padding-left:15px !important;
		padding-right:15px !important;
	}
}
@media screen and (max-width: 1200px) {
  .flight-hacks-wine .overlap-6 {
	position:relative;
	width: 100%;
	height: 75px;
	justify-content: center;
	align-items: center;
	display: flex;
  }

  .flight-hacks-wine .frame-22 {
	display: flex;
	align-items: center;

	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	top: 0;
  }
}
@media screen and (min-width: 992px) {
  .head1  {
	text-align: right;
    justify-content: right;
	align-items: right;
	padding:0;
	margin: auto;
  }
  
  .head2 {
	text-align: left;
    justify-content: left;
	align-items: left;
	padding:0;
	margin: auto;
  }
  .text-wrapper-13{
	
	width:100%;
  }
  
  
}

